import React from 'react'
import Navigation from '../../components/Navigation/Navigation'
import styles from './CheckNft.module.scss'
import Nft from '../../assets/image/Card-02.png'
import { useSearchParams } from 'react-router-dom'

function CheckNft () {
  const [searchParams] = useSearchParams()

  return (
    <div className={styles.section}>
      <Navigation />
      <div className={styles.section__bgImg} />
      <div className={styles.section__container}>
        <div className={styles.section__mainTitleContainer}>
          <div className={styles.section__title}>
            {searchParams.get('mail') ? searchParams.get('mail') : 'Prepare for future battles with our NFT'}
          </div>
          {searchParams.get('mail') && (
            <div className={styles.section__title}>
              Check your NFT here!!!
            </div>
          )}
        </div>
        <div className={styles.section__mainContent}>
          <img src={Nft} alt='Card' />
          <div className={styles.section__mainContentTextContainer}>
            <div className={styles.section__mainContentText}>
              <p>
                125,000 $ZMBI/per NFT
              </p>
            </div>
            <div className={styles.section__mainContentText}>
              <p>
                10% of your referral spendings
              </p>
            </div>
            <div className={styles.section__mainContentText}>
              <p>
                Forever doubled ammo
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckNft

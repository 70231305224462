import React from 'react'
import styles from './Footer.module.scss'
import Timer from '../../assets/image/timer.png'
import Shadow from '../../assets/image/shadowFooter.svg'
import Ellipse from '../../assets/image/ellipse.svg'
import Hand from '../../assets/image/handSmall.svg'
import Journey from '../../assets/image/journey.jpg'

function Footer() {
  return (
    <div className={styles.footer}>
      <img className={styles.footer__background} src={Journey} alt="background" />
      <img className={styles.footer__ellipse} src={Ellipse} alt="ellipse" />
      <img className={styles.footer__shadow} src={Shadow} alt="shadow" />
      <div className={styles.footer__timer}>
        <img src={Timer} alt="timer" />
      </div>
      <div className={styles.footer__links}>
        <a
          href="https://x.com/cyber_zombi_bot"
          target="_blank"
          className={styles.footer__links__item}
        >
          X (TWITTER)
        </a>
        <a href="https://t.me/ZombiBotChat" target="_blank" className={styles.footer__links__item}>
          TELEGRAM
        </a>
        <a
          href="https://zombi-bot.gitbook.io/ciber-zombi-bot"
          target="_blank"
          className={styles.footer__links__item}
        >
          GITBOOK
        </a>
        <a
          href="https://discord.com/invite/XfF5h5Y7N9"
          target="_blank"
          className={styles.footer__links__item}
        >
          DISCORD
        </a>
        <button className={styles.footer__links__button}>
          <a href="https://t.me/ZombiBotBeta_bot/play" target="_blank">
            PLAY
          </a>
        </button>
      </div>
      <div className={styles.footer__text}>
        <img src={Hand} alt="hand" />
        <div className={styles.footer__text__zmbi}>$ZMBI</div>
        <div className={styles.footer__text__rocketing}>Rocketing</div>
        <div className={styles.footer__text__date}>5 November, 2024</div>
      </div>
    </div>
  )
}

export default Footer
